<script setup>
import { useThemeStore } from '~/stores/theme';

const themeStore = useThemeStore()
const { primary, surface, preset, menuMode, isDarkTheme } = storeToRefs(themeStore)
const { presets, primaryColors, surfaces, getPresetExtColor } = useThemeSetting()

const presetOptions = ref(Object.keys(presets));

const menuModeOptions = ref([
    { label: 'Static', value: 'static' },
    { label: 'Overlay', value: 'overlay' }
]);

function updateColors(type, color) {
    if (type === 'primary') {
        themeStore.setPrimary(color.name);
    } else if (type === 'surface') {
        themeStore.setSurface(color.name);
    }
}

function onPresetChange() {
    themeStore.setPreset(preset.value);
}

function onMenuModeChange() {
    themeStore.setMenuMode(menuMode.value);
}
</script>

<template>
    <div
        class="p-4"
    >
        <div class="flex flex-col gap-4">
            <div>
                <span class="text-sm text-muted-color font-semibold">Primary</span>
                <div class="pt-2 flex gap-2 flex-wrap justify-between">
                    <Button
                        v-for="primaryColor of primaryColors"
                        :key="primaryColor.name"
                        :title="primary === primaryColor.name"
                        :class="['border-none w-5 h-5 rounded-full p-0 cursor-pointer outline-none outline-offset-1', { 'outline-primary': primary === primaryColor.name }]"
                        :style="{ backgroundColor: `${primaryColor.name === 'noir' ? 'var(--text-color)' : primaryColor.palette['500']}` }"
                        @click="updateColors('primary', primaryColor)"
                    />
                </div>
            </div>
            <div>
                <span class="text-sm text-muted-color font-semibold">Surface</span>
                <div class="pt-2 flex gap-2 flex-wrap justify-between">
                    <Button
                        v-for="surfaceColor of surfaces"
                        :key="surfaceColor.name"
                        :title="surfaceColor.name"
                        :class="[
                            'border-none w-5 h-5 rounded-full p-0 cursor-pointer outline-none outline-offset-1',
                            { 'outline-primary': surfaceColor ? surface === surfaceColor.name : isDarkTheme() ? surfaceColor.name === 'zinc' : surfaceColor.name === 'slate' }
                        ]"
                        :style="{ backgroundColor: `${surfaceColor.palette['500']}` }"
                        @click="updateColors('surface', surfaceColor)"
                    />
                </div>
            </div>
            <div class="flex flex-col gap-2">
                <span class="text-sm text-muted-color font-semibold">Presets</span>
                <SelectButton v-model="preset" :options="presetOptions" :allow-empty="false" @change="onPresetChange" />
            </div>
            <div class="flex flex-col gap-2">
                <span class="text-sm text-muted-color font-semibold">Menu Mode</span>
                <SelectButton v-model="menuMode" :options="menuModeOptions" :allow-empty="false" option-label="label" option-value="value" @change="onMenuModeChange" />
            </div>
        </div>
    </div>
</template>
