<script setup lang="ts">
import { useThemeStore } from '~/stores/theme';
const user = useAuthenticatedUser();
const themeStore = useThemeStore()

const topBarUserMenuVisible = ref(null as any)
const topBarThemeSettingsVisible = ref(null as any)

const userMenuItems = [{
  label: "User: " + user.value?.email, items: [
    { label: "Profile", icon: "pi pi-user", route: "/core/settings/settings" },
    { label: "Sign-out", icon: "pi pi-sign-out", command: logout },
  ]
}]

function toggleTopBarThemeSettingsMenu(event: Event) { topBarThemeSettingsVisible.value.toggle(event) }
function toggleTopBarUserMenu(event: Event) {
  topBarUserMenuVisible.value.toggle(event)
}

async function logout() {
  await $fetch("/api/auth/logout", {
    method: "POST"
  });
  await navigateTo('/', { external: true })
}
</script>

<template>
  <div class="flex justify-between items-center px-8 bg-surface-0 dark:bg-surface-950 relative lg:static h-[60px]">
    <div class="flex">
      <a v-styleclass="{
        selector: '#app-sidebar',
        enterFromClass: 'hidden',
        enterActiveClass: 'animate-fadeinleft',
        leaveToClass: 'hidden',
        leaveActiveClass: 'animate-fadeoutleft',
        hideOnOutsideClick: true
      }" class="cursor-pointer block lg:hidden mr-4 mt-1">
        <i class="pi pi-bars text-4xl" />
      </a>

      <IconField icon-position="left">
        <InputIcon class="pi pi-search text-surface-700 dark:text-surface-100" />
        <InputText type="text" class="border-0 w-40 sm:w-80" placeholder="Suche" />
      </IconField>
    </div>

    <div class="layout-topbar-action flex">
      <Button text rounded @click="themeStore.toggleDarkMode">
        <i :class="['pi', { 'pi-moon': themeStore.isDarkTheme, 'pi-sun': !themeStore.isDarkTheme }]" class="text-2xl" />
      </Button>
      <Button text rounded @click="toggleTopBarThemeSettingsMenu">
        <i class="pi pi-palette text-2xl" />
      </Button>
      <Button text rounded @click="toggleTopBarUserMenu">
        <i class="pi pi-user text-2xl" />
      </Button>
    </div>



  </div>
  <!-- <div class="layout-topbar" v-if="false">
    <div class="layout-topbar-logo-container">
      <Button severity="contrast" text rounded class="layout-menu-button layout-topbar-action"
        @click="themeStore.onMenuToggle">
        <i class="pi pi-bars" />
      </Button>
      <router-link to="/" class="layout-topbar-logo">
        <span></span>
      </router-link>
    </div>

    <div class="layout-topbar-actions">
      <div class="layout-config-menu">
        <Button class="layout-topbar-action" severity="contrast" text rounded @click="themeStore.toggleDarkMode">
          <i :class="['pi', { 'pi-moon': themeStore.isDarkTheme, 'pi-sun': !themeStore.isDarkTheme }]" />
        </Button>
        <Button severity="contrast" text rounded class="layout-topbar-action" @click="toggleTopBarThemeSettingsMenu">
          <i class="pi pi-palette" />
        </Button>
        <Button severity="contrast" text rounded class="layout-topbar-action" @click="toggleTopBarUserMenu">
          <i class="pi pi-user" />
          <span>Profile</span>
        </Button>
      </div>
    </div>
  </div> -->
  <client-only>
    <Menu id="overlay_menu" ref="topBarUserMenuVisible" :model="userMenuItems" append-to="body" :popup="true">
      <template #item="{ item, props }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
          <a v-ripple :href="href" v-bind="props.action" @click="navigate">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </router-link>
        <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </template>
    </Menu>
    <Menu ref="topBarThemeSettingsVisible" class="w-full md:w-60" :popup="true">
      <template #start>
        <LayoutAppConfigurator />
      </template>
    </Menu>
  </client-only>
</template>
