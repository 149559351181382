export function useNavigationMenu() {
  const { d, t, n, locale, locales, setLocale } = useI18n()
  const separator = h("hr")

  interface MenuItem {
    label: string
    to?: string
    icon?: string
    items?: MenuItem[]
    separator?: Boolean
  }

  const menu = computed(() => {
    return [
      { label: "Startseite", to: "/core", icon: "i-mdi:home-outline" },
      { label: "Predigten", to: "/core/sermons", icon: "i-simple-line-icons:earphones" },
      { label: "Livestream", to: "/core/livestream", icon: "solar:play-stream-bold" },
      {
        label: "Optionen",
        icon: "i-mdi:cog-outline",
        items: [
          { label: "Benutzerprofil", to: "/core/settings/profile", icon: "mdi:account-cog" },
          { label: "Darstellung", to: "/core/settings/appearance", icon: "mdi:color" },
          {
            label: "Serververwaltung",
            icon: "mdi:server",
            items: [
              { label: "Gemeinde", to: "/core/settings/congregation", icon: "fa-solid:church" },
              { label: "Benutzer", to: "/core/settings/users", icon: "mdi:account-multiple" },
              { label: "Medien", to: "/core/settings/media", icon: "mdi:media-network" },
              { label: "Audiorecorder", to: "/core/settings/record", icon: "fluent:mic-record-20-filled" },
              { label: "Transkribierung", to: "/core/settings/transcription", icon: "tabler:file-text-spark" },
            ],
          },
        ],
      },
      { label: "Quicktest", to: "/core/develope/quicktests", icon: "mdi:code-tags" },
    ] as MenuItem[]
  })

  return { menu }
}
