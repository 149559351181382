<script lang="ts" setup>
import _ from 'lodash';

const { menu } = useNavigationMenu()

const route = useRoute();

const isActiveMenu = ref(false);
const itemKey = ref(null as string | null);

const findAncestors = (data: typeof menu.value, currentPath: string, ancestors: typeof menu.value = []): typeof menu.value | null => {
    for (const item of data) {
        if (item.to === currentPath) {
            return [...ancestors]; // Return list with all ancestors and the found item
        }

        if (item.items) {
            const result: typeof menu.value | null = findAncestors(item.items, currentPath, [...ancestors, item]);
            if (result) {
                return result;
            }
        }
    }
    return null; // If no matching value found
}

const checkActiveRoute = (item: typeof menu.value[number]) => {
    let isActive = false
    let activeAnchestors: typeof menu.value = []
    let isChildActive = false

    if (!item.to) {
        activeAnchestors = findAncestors(menu.value, route.path) || []
        isChildActive = _.some(activeAnchestors, (x) => _.isEqual(x, item))
    }

    if (route.path === item.to || isChildActive)
        isActive = true
    return isActive;
};

</script>

<template>
    <div id="app-sidebar"
        class="layout-sidebar h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-10 w-72 lg:w-28 select-none">
        <div class="flex flex-col h-full">
            <!-- <div class="flex items-center justify-center flex-shrink-0 h-[60px]">
                Logo
            </div> -->
            <div class="mt-4">
                <ul class="list-none p-4 m-0">
                    <template v-for="(item, i) in menu" :key="item">
                        <!-- simple router-link -->
                        <li v-if="item.to && (!item.items || item.items.length == 0)"
                            :class="{ 'active-menuitem': checkActiveRoute(item) }">
                            <router-link :to="item.to"
                                class="menu-item flex flex-row lg:flex-col items-center cursor-pointer p-4 lg:justify-center rounded-border duration-150 transition-colors">
                                <Icon v-if="item.icon" :name="item.icon" size="2em"
                                    class="mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg" />
                                <span class="font-medium inline text-base lg:text-xs lg:block">{{ item.label }}</span>
                            </router-link>
                            <li v-if="item.separator" class="menu-separator" />
                        </li>
                        <!-- open submenus -->
                        <li class="relative" v-if="item.items && item.items.length > 0"
                            :class="{ 'active-menuitem': checkActiveRoute(item) }">
                            <a v-styleclass="{
                                selector: '@next',
                                enterFromClass: 'hidden',
                                leaveToClass: 'hidden',
                                hideOnOutsideClick: true
                            }"
                                class="menu-item flex flex-row lg:flex-col items-center cursor-pointer p-4 lg:justify-center rounded-border duration-150 transition-colors">
                                <Icon v-if="item.icon" :name="item.icon" size="2em" class="text-base lg:text-lg" />
                                <span class="font-medium inline text-base lg:text-xs lg:block">{{ item.label }}</span>
                                <i class="pi pi-chevron-down ml-auto lg:hidden" />
                            </a>
                            <ul
                                class="list-none pl-4 pr-0 py-0 lg:p-4 m-0 lg:ml-4 hidden overflow-y-hidden transition-all duration-[400ms] ease-in-out static rounded-r lg:absolute left-full top-0 z-10 shadow-none lg:shadow w-full lg:w-80">
                                <!-- submenu Items -->
                                <template v-for="(subitem, n) in item.items" :key="subitem">
                                    <li v-if="subitem.to && (!subitem.items || subitem.items.length == 0)"
                                        :class="{ 'active-menuitem': checkActiveRoute(subitem) }">
                                        <router-link :to="subitem.to"
                                            class="menu-item flex items-center cursor-pointer p-4 rounded-border duration-150 transition-colors">
                                            <Icon v-if="subitem.icon" :name="subitem.icon" size="1.5em" class="mr-2" />
                                            <span class="font-medium">{{ subitem.label }}</span>
                                        </router-link>
                                    </li>
                                    <li v-if="subitem.items && subitem.items.length > 0"
                                        :class="{ 'active-menuitem': checkActiveRoute(subitem) }">
                                        <a v-styleclass="{
                                            selector: '@next',
                                            toggleClass: 'hidden'
                                        }"
                                            class="menu-item flex items-center cursor-pointer p-4 rounded-border duration-150 transition-colors">
                                            <Icon v-if="subitem.icon" :name="subitem.icon" size="1.5em" class="mr-2" />
                                            <span class="font-medium">{{ subitem.label }}</span>
                                            <i class="pi pi-chevron-down ml-auto" />
                                        </a>
                                        <ul class="list-none py-0 pl-4 pr-0 m-0 overflow-y-hidden transition-all duration-[400ms] ease-in-out"
                                            :class="{ 'hidden': !checkActiveRoute(subitem) }">

                                            <template v-for="(subsubitem, k) in subitem.items" :key="subsubitem">
                                                <li v-if="subsubitem.to && (!subsubitem.items || subsubitem.items.length == 0)"
                                                    :class="{ 'active-menuitem': checkActiveRoute(subsubitem) }">
                                                    <router-link :to="subsubitem.to"
                                                        class="menu-item flex items-center cursor-pointer p-4 rounded-border duration-150 transition-colors">
                                                        <Icon v-if="subsubitem.icon" :name="subsubitem.icon"
                                                            size="1.5em" class="mr-2" />
                                                        <span class="font-medium">{{ subsubitem.label }}</span>
                                                    </router-link>
                                                </li>
                                            </template>
                                        </ul>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="mt-auto">
                <!-- <hr class="mb-4 mx-4 border-t border-0 border-surface-800" />
                <a
                    class="m-4 flex flex-row lg:flex-col items-center cursor-pointer p-4 lg:justify-center hover:bg-surface-800 rounded-border text-surface-300 hover:text-white duration-150 transition-colors">
                    <img src="https://fqjltiegiezfetthbags.supabase.co/storage/v1/render/image/public/block.images/blocks/avatars/circle/avatar-f-1.png"
                        class="mr-2 lg:mr-0 w-8 h-8" />
                    <span class="font-medium inline lg:hidden">Amy Elsner</span>
                </a> -->
            </div>
        </div>
    </div>
    <!-- <div id="app-sidebar-5"
        class="bg-gray-900 h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-10 border-r border-gray-800 w-72 lg:w-28 select-none">
        <div class="flex flex-col h-full">
            <div class="flex items-center justify-center flex-shrink-0 bg-orange-500" style="height:60px">
            </div>
            <div class="mt-3">
                <ul class="list-none p-3 m-0">
                    <template v-for="(item, i) in menu" :key="item">
    <li>
        <router-link v-if="item.to && !item.items !== false" :to="item.to" v-ripple
            class="flex flex-row lg:flex-col items-center cursor-pointer p-3 lg:justify-center hover:bg-gray-800 rounded text-gray-300 hover:text-white transition duration-150">
            <i :class="item.icon" class="mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-lg"></i>
            <span class="font-medium inline text-base lg:text-xs lg:block">{{ item.label }}</span>
        </router-link>
        <li v-if="item.separator" class="menu-separator" />
    </li>
</template>
</ul>
</div>
</div>
</div> -->
</template>

<style lang="scss" scoped></style>
