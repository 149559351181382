<script setup lang='ts'>

</script>

<template>
  <div class="min-h-screen flex relative lg:static bg-surface-50 dark:bg-surface-950">
    <LayoutAppSidebar />
    <div class="min-h-screen flex flex-col relative flex-auto">
      <LayoutAppTopbar />
      <div class="p-4 flex flex-col flex-auto">
        <slot />
      </div>
      <LayoutAppFooter />
    </div>
  </div>
  <ClientOnly>
    <Toast />
  </ClientOnly>
</template>

<style lang="scss">
#workspace {
  padding-left: 220px;
  transition: 0.3s ease;
}

#workspace.collapsed {
  padding-left: 65px;
}

#workspace.onmobile {
  padding-left: 65px;
}
</style>
