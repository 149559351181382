<script setup></script>

<template>
    <div class="layout-footer shadow-inner">
        <vue-plyr class="" :controls="['current-time']">
            <audio controls playsinline>
                <source :src="audioUrl" type="audio/mpeg" />
            </audio>
        </vue-plyr>
    </div>
</template>

<style lang="scss">
.plyr {
    --plyr-audio-controls-background: white;

    .plyr__controls {
        --plyr-color-main: var(--p-primary-color);
        --plyr-audio-control-color: var(--p-text-color);
    }

}

.app-dark .plyr {
    --plyr-audio-controls-background: var(--p-surface-800);
}
</style>
